"use client";
import NextImage from "@/components/Common/NextImage";
import { PATH_NAME } from "@/constants/path-name.constant";
import { useTranslations } from "next-intl";
import Link from "next/link";

const FindNeeds = () => {
  const t = useTranslations();
  return (
    <section className="provide-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-9">
            <div className="section-header aos" data-aos="fade-up">
              <h2>
                {t.rich("home.weAreHereToHelpYouFindYourNeeds", {
                  span: (chunks) => <span>{chunks}</span>,
                })}
              </h2>
              <p>{t("home.over74KListingsOfTaskAvailableTodayForYou")}</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="provide-box">
              <div className="provide-icon">
                <NextImage
                  src="/assets/img/icons/ipad-icon.svg"
                  alt="icon"
                  width={24}
                  height={24}
                />
              </div>
              <h3>{t("home.receiveTask")}</h3>
              <p>{t("home.receiveTaskDescription")}</p>
              <Link href={PATH_NAME.TASKS} className="btn btn-primary">
                {t("home.exploreTask")}
                <i className="fa-solid fa-caret-right" />
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="provide-box">
              <div className="provide-icon">
                <NextImage
                  src="/assets/img/icons/service-icon.svg"
                  alt="icon"
                  width={24}
                  height={24}
                />
              </div>
              <h3>{t("menu.create-task")}</h3>
              <p>{t("home.createTaskDescription")}</p>
              <Link href={PATH_NAME.ADD_TASK} className="btn btn-primary">
                {t("home.createTaskNow")}
                <i className="fa-solid fa-caret-right" />
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="provide-box">
              <div className="provide-icon">
                <NextImage
                  src="/assets/img/icons/user-icon-01.svg"
                  alt="icon"
                  width={24}
                  height={24}
                />
              </div>
              <h3>{t("home.joinUs")}</h3>
              <p>{t("home.joinUsDescription")}</p>
              <Link href={PATH_NAME.SIGN_UP} className="btn btn-primary">
                {t("home.getStarted")}
                <i className="fa-solid fa-caret-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindNeeds;
