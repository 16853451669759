"use client";
import { useTranslations } from "next-intl";
import Slider from "react-slick";
import { TaskCard } from "../../Task/TaskList/components/TaskCard";
import { ITask } from "@/types/tasks.types";

const ExploreGigs = ({ tasks }: { tasks: ITask[] }) => {
  const t = useTranslations();
  // const [tasks, setTasks] = useState<ITask[]>(tasksList);
  // const [selectedItems, setSelectedItems] = useState<boolean[]>(
  //   Array(20).fill(false)
  // );

  // const handleItemClick = (index: number) => {
  //   setSelectedItems((prev) => {
  //     const updated = [...prev];
  //     updated[index] = !updated[index];
  //     return updated;
  //   });
  // };

  const mainSliderSettings = {
    dots: false,
    nav: false,
    infinite: true,
    speed: 500,
    swipe: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 700,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  // const imageSliderSettings = {
  //   dots: true,
  //   nav: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   draggable: true,
  //   tochMove: true,
  //   swipe: true,
  //   slidesToScroll: 1,
  // };

  const tabs = [
    { id: "popular", label: t("common.popular"), content: tasks },
    { id: "latest", label: t("common.latest"), content: tasks },
    { id: "trend", label: t("common.trending"), content: tasks },
  ];

  return (
    <section className="explore-gigs-section">
      <div className="container">
        <div className="section-head d-flex">
          <div className="section-header aos" data-aos="fade-up">
            <h2>
              <span>{t("common.explore")}</span> {t("common.ourTasks")}.
            </h2>
          </div>
          <div className="section-tab">
            <ul
              className="nav nav-pills inner-tab aos"
              id="pills-tab"
              role="tablist"
              data-aos="fade-up"
            >
              {tabs.map((tab) => (
                <li key={tab.id} className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      tab.id === "popular" ? "active" : ""
                    }`}
                    id={`pills-${tab.id}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#pills-${tab.id}`}
                    type="button"
                    role="tab"
                    aria-controls={`pills-${tab.id}`}
                    aria-selected={tab.id === "popular"}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="tab-content dashboard-tab">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`tab-pane fade ${
                tab.id === "popular" ? "show active" : ""
              }`}
              id={`pills-${tab.id}`}
              role="tabpanel"
              aria-labelledby={`pills-${tab.id}-tab`}
            >
              <div className="row aos" data-aos="fade-up" data-aos-delay={500}>
                <div className="col-md-12">
                  <div className="gigs-card-slider">
                    <Slider {...mainSliderSettings}>
                      {tab?.content?.map((gig) => (
                        // <GigCard
                        //   key={gig.id}
                        //   gig={gig}
                        //   onFavoriteClick={handleItemClick}
                        //   isFavorite={selectedItems[gig.id]}
                        //   sliderSettings={imageSliderSettings}
                        // />
                        <TaskCard
                          key={gig.id}
                          task={gig}
                          isSelected={false}
                          className="w-100"
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExploreGigs;
