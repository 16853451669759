import NextImage from "@/components/Common/NextImage";
import { PATH_NAME } from "@/constants/path-name.constant";
import { ITask } from "@/types/tasks.types";
import { formatMoney } from "@/utils/formatData";
import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";

export const TaskCard = ({
  task,
  isSelected,
  onFavoriteClick = undefined,
  onEditClick = undefined,
  onDeleteClick = undefined,
  className = "col-xl-4 col-md-6",
}: {
  task: ITask;
  isSelected: boolean;
  onFavoriteClick?: (id: number) => void;
  onEditClick?: (id: number) => void;
  onDeleteClick?: (id: number) => void;
  className?: string;
}) => {
  const t = useTranslations();
  const router = useRouter();
  return (
    <div
      onClick={() => router.push(PATH_NAME.TASKS + "/" + task?.jobSlug)}
      className={className + " cursor-pointer"}
    >
      <div className="gigs-grid">
        {/* Image Section */}
        <div className="gigs-img">
          <div className="img-slider">
            <NextImage
              src={task?.attachment || ""}
              width={330}
              height={183}
              className="img-fluid"
              alt="img"
            />
          </div>

          {/* Badges */}
          {/* {(isFeatured || isHot) && (
            <div className="card-overlay-badge">
              {isFeatured && (
                <Link href={PATH_NAME.SERVICE}>
                  <span className="badge bg-warning">
                    <i className="feather icon-star" />
                    Featured
                  </span>
                </Link>
              )}
              {isHot && (
                <Link href={PATH_NAME.SERVICE}>
                  <span className="badge bg-danger">
                    <i className="fa-solid fa-meteor" />
                    Hot
                  </span>
                </Link>
              )}
            </div>
          )} */}

          {/* Favorite Button */}

          <div onClick={(e) => e.stopPropagation()} className="fav-selection">
            {onFavoriteClick && (
              <button
                className={`fav-icon ${isSelected ? "favourite" : ""}`}
                onClick={() => onFavoriteClick(task?.id || 0)}
              >
                <i className="feather icon-heart" />
              </button>
            )}

            {onEditClick && (
              <button
                className="edit-icon"
                onClick={() => onEditClick(task?.id || 0)}
              >
                <i className="feather icon-edit" />
              </button>
            )}
            {onDeleteClick && (
              <button
                className="delete-icon"
                onClick={() => onDeleteClick(task?.id || 0)}
              >
                <i className="feather icon-trash-2" />
              </button>
            )}
          </div>
        </div>

        {/* Content Section */}
        <div className="gigs-content">
          {/* Category and Rating */}
          <div className="gigs-info">
            <div className="badge bg-primary-light">{task?.categoryName}</div>
            <div className="star-rate">
              <span>
                <i className="fa-solid fa-star" />
                {task?.reputation?.toFixed(1)}
              </span>
            </div>
          </div>

          {/* Title */}
          <div className="gigs-title">
            <h3>{task?.title}</h3>
          </div>

          {/* User Info */}
          <div className="gigs-user-info">
            <div className="gigs-user">
              <NextImage
                src={""}
                width={30}
                height={30}
                alt={task?.displayName || ""}
              />
              <p>
                {t("text.by")} {task?.displayName}
              </p>
            </div>
            <div className="gigs-loc">
              <p>
                <i className="feather icon-map-pin" />
                {task?.subcategoryName}
              </p>
            </div>
          </div>

          {/* Footer */}
          <div className="gigs-card-footer">
            <h5>{formatMoney(task?.total || 0)} VND</h5>
            <span className="badge">{task?.vacancyAvailable}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
